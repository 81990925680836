<template>
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7503 15.8583H8.69056L7.146 9.79247C7.07269 9.51344 6.91703 9.26676 6.68806 9.1505C6.11664 8.85833 5.48696 8.6258 4.80005 8.50853V8.27499H8.11813C8.57607 8.27499 8.91953 8.6258 8.97677 9.03323L9.77817 13.4087L11.8369 8.27499H13.8394L10.7503 15.8583ZM14.9843 15.8583H13.039L14.6408 8.27499H16.5861L14.9843 15.8583ZM19.1028 10.3758C19.16 9.96738 19.5035 9.73384 19.9042 9.73384C20.5338 9.6752 21.2197 9.79248 21.7922 10.0836L22.1356 8.45091C21.5632 8.21737 20.9335 8.1001 20.3621 8.1001C18.4741 8.1001 17.1003 9.1505 17.1003 10.6083C17.1003 11.7174 18.0734 12.2997 18.7603 12.6505C19.5035 13.0003 19.7897 13.2338 19.7324 13.5836C19.7324 14.1083 19.16 14.3419 18.5886 14.3419C17.9017 14.3419 17.2147 14.167 16.5861 13.8748L16.2426 15.5086C16.9295 15.7997 17.6727 15.917 18.3596 15.917C20.4766 15.9746 21.7922 14.9252 21.7922 13.3501C21.7922 11.3666 19.1028 11.2503 19.1028 10.3758ZM28.6 15.8583L27.0555 8.27499H25.3965C25.053 8.27499 24.7095 8.50853 24.5951 8.85833L21.7349 15.8583H23.7374L24.1371 14.7503H26.5976L26.8265 15.8583H28.6ZM25.6827 10.3172L26.2541 13.1752H24.6523L25.6827 10.3172Z"
      fill="#172B85"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style></style>
